body {
    /* background: #f0f0f0; */
    background: #bbbbbb;
}
.main {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 60px;
    height: 100vh;
}