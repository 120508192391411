.menu-trigger {
    /* position: fixed; */
    background: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    /* bottom: 26px;
    right: 20px; */
    width: 32px;
    height: 32px;
    border-radius: 10px;
    cursor: pointer;
    /* padding: 10px; */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.menu-trigger::before,
.menu-trigger::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 4px;
    background: rgba(0, 140, 255, 1);
    border-radius: 2px;
    transition: all 0.3s ease;
}

.menu-trigger.active {
    background: linear-gradient(45deg,#008cff,#454545);
    background: rgba(0, 140, 255, 1);
    right: 10px;
}
.menu-trigger::before {
    transform: rotate(90deg);
}

.menu-trigger.active::before {
    transform: rotate(45deg);
    background: white;
}

.menu-trigger.active::after {
    transform: rotate(-45deg);
    background: white;
}

.corner-menu {
    position: relative;
    display: inline;
    background: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    bottom: 14px;
    right: 48px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
    transform: scale(0.8);
    opacity: 0;
    visibility: hidden;
    z-index: 999;
}

.dark-mode .corner-menu {
    background: rgba(0,0,0, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.corner-menu.active {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.menu-item {
    display: inline-block;
    margin-right: 8px;
}
.menu-item:last-child {
    margin-right: 0px;
}

a.footIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #454545; */
    color: #454545;
    width: 36px;
    height: 36px;
    cursor: pointer;
    font-size: 20px;
    /* padding: 4px 6px 8px; */
    border-radius: 10px;
    transition: all 0.3s ease;
}
.dark-mode a.footIcon {
    /* background: #ffffff; */
    color: #ffffff;
}

a.footIcon:hover {
    box-shadow: #6b6b6b 0 0 6px;
}
.dark-mode a.footIcon:hover {
    box-shadow: #f7fbfe 0 0 6px;
}

.wa a.footIcon {
    background: #25D366;
    color: #ffffff;
}
.gmap a.footIcon {
    background:linear-gradient(90deg, #4285f4 20%, #ffffff 20%, #ffffff 75%, #FBBB05 80%, #ffffff 80%);
    background:linear-gradient(90deg, #4285f4 20%, #ffffff 20%);
    color: #E94335;
}
.dark-mode .gmap a.footIcon {
    background:linear-gradient(90deg, #4285f4 20%, #454545 20%);
    color: #ffffff;
}

.dark-mode .wa a.footIcon:hover {
    color: #ffffff;
    box-shadow: #f7fbfe 0 0 6px;
}


span.footIconText {
    color: white;
    position: absolute;
    /* background: #008cff; */
    left: 0%;
    bottom: 90%;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    /* padding: 12px; */
    /* padding: 2px 6px 0px; */
    pointer-events: none;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    white-space: nowrap;
    mix-blend-mode: difference;
}
.menu-item:hover span.footIconText {
    opacity: 1;
    mix-blend-mode: difference;
}
