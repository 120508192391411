.chat-container {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0 0 16px 16px;
}

.dark-mode .chat-container {
  background: #323232;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.top-space {
  display: block;
  background: transparent;
  width: 100%;
  height: 20px;
}

.chat-messages {
  /* height: calc(100vh -126px); */
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  /* padding-top: 90px; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  /* overflow: auto; */
}

.chat-message {
  margin-bottom: 16px;
  border-radius: 8px;
}

.chat-message.question {
  /* background-color: #e1f5fe; */
  display: flex;
  /* align-items: clamp(center,center, center); */
  align-items: center;
  color: #454545;
  max-width: 80%;
  word-wrap: break-word;
  /* align-self: flex-start; */
  text-align: left;
}
.dark-mode .chat-message.question {
  color: #ffffff;
}
.chat-message.question::before, .typing::before {
  content: 'Brey';
  display: inline-block;
  position: relative;
  background: #ff4c00;
  color: #ffffff;
  min-width: 32px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  align-content: center;
  margin-right: 6px;
  border-radius: 50px;
  transition: all 0.4s ease;
}

.typing {
  /* font-style: italic; */
  color: #888;
}
.dark-mode .typing {
  color: #f1f1f1;
}
.chat-message.response::after {
  content: '';
  display: none;
  position: relative;
  font-size: 12px;
  text-align: right;
  margin-bottom: 4px;
  transition: all 0.4s ease;
}


.chat-message.response {
  background: #4caf50;
  color: #ffffff;
  max-width: 72%;
  align-self: flex-end;
  word-wrap: break-word;
  text-align: left;
  /* word-break: break-all; */
  /* overflow-wrap: break-word; */
  padding: 12px;
  /* margin-left: 32%; */
  border-radius: 12px 12px 0 12px;
  transition: all 0.4s ease;
  animation-name: example;
  animation-duration: 1s;
}
.dark-mode .chat-message.response {
  /* background: #454545; */
}

@keyframes example {
  0%   {
    opacity: 0;
    margin-top: 20px;
  }
  25%  {opacity: 0.25;}
  50%  {opacity: 0.5;}
  100% {opacity: 1;
    margin-top: 0px;
  }
}


.chat-message.system {
  /* background: #fff9c4; */
  color: #454545;
  width: 100%;
  /* padding: 10px; */
  font-weight: bold;
  font-style: italic;
  text-align: center;
  margin-bottom: 4px;
}
.dark-mode .chat-message.system {
  color: #f1f1f1;
}

.chat-input {
  background: rgba(255, 255, 255, 1);
  background: #f1f1f1;
  /* -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px); */
  min-height: 60px;
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
  padding: 12px;
  /* border-radius: 0 0 16px 16px; */
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
}
.dark-mode .chat-input {
  background: #454545;
}

.input-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 5px;
  /* border: 1px solid #ddd; */
  border-radius: 16px;
}

.text-input {
  background: transparent;
  color: #454545;
  min-height: 32px;
  flex-grow: 1;
  margin: 0 6px;
  /* word-wrap: break-word; */
  /* word-break: break-all; */
  overflow-wrap: break-word;
  border: 0px solid #ddd;
  /* border-right: 1px solid #ddd; */
  border-radius: 0px;
}
.dark-mode .text-input {
  /* background: #454545; */
  color: #ffffff;
}
input:focus, button:focus, button:focus {
  outline: none;
}

.send-button {
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
  /* padding: 5px 10px; */
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 4px rgba(76, 175, 80, 0.4);
  transition: all 0.2s ease;
}

.send-button:active {
  align-items: flex-start;
  /* background: #454545; */
  box-shadow: 0px 2px 2px 2px rgba(76, 175, 80, 0.4);
}

.send-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.choice-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  flex-grow: 1;
}

.choice-button {
  padding: 2px 6px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 12px;
  cursor: pointer;
}

.choice-button.selected {
  background-color: #ff4c00;
  color: white;
}

.image-upload-container {
  /* margin-top: 10px; */
  flex-grow: 1;
}

.file-input {
  /* margin-bottom: 10px; */
}

.filelabel {
  width: 120px;
  border: 2px dashed #cccccc;
  border-radius: 5px;
  display: inline-block;
  padding: 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
}
.filelabel svg,
.filelabel .title {
color: #454545;
transition: 200ms color;
}
.filelabel:hover {
border: 2px solid #888888;
}
.filelabel:hover svg,
.filelabel:hover .title {
color: #454545;
}
#FileInput{
  display:none;
}

.dark-mode .filelabel svg,
.dark-mode .filelabel .title, .dark-mode .filelabel:hover svg,
.dark-mode .filelabel:hover .title {
color: #ffffff;
}


.image-preview, .uploaded-image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}
.image-preview {
  display: block;
  height: 45px;
  margin-bottom: 6px;
}

.language-selection {
  text-align: center;
  /* margin-top: 50px; */
}

.language-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.language-button {
  padding: 6px 10px;
  font-size: 14px;
  /* background-color: #4caf50; */
  background: #ff4c00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.language-button:hover {
  background-color: #45a049;
}

@media screen and (max-width: 970px) {
  .top-space {
    height: 90px;
  }
}